import { useStaticQuery, graphql } from 'gatsby'

const useDocumentsQuery = (name) => {
  const data = useStaticQuery(graphql`
    query Documents {
      allGoogleDocs {
        nodes {
          document {
            name
          }
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return data.allGoogleDocs.nodes.find((item) => item.document.name === name)
    .childMarkdownRemark.html
}

export default useDocumentsQuery
