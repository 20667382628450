import React from 'react'
import Layout from '../components/Layout/Layout'
import useDocumentsQuery from '../hooks/useDocumentsQuery'

const TermsOfServicePage = () => {
  const html = useDocumentsQuery('Terms and conditions')

  return (
    <Layout style={{ maxWidth: '950px' }}>
      <div className="text-page" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default TermsOfServicePage
